import { useMutation, useQuery } from '@apollo/client'
import {
  Core_OrderV3,
  Core_OrderV3AuthorizedSignerRequestData,
  Core_OrderV3Task,
  Core_OrderV3TaskCompletionType,
  Core_OrderV3TaskKey,
  Core_OrderV3TaskRequestData_DataOneof_ContributionAgreementSignedTaskRequestData,
  Core_OrderV3TaskRequestData_DataOneof_LeaseAssignmentPreparedTaskRequestData,
  Core_OrderV3TaskRequestData_DataOneof_PersonalInformationCompletedTaskRequestData,
  Core_OrderV3TaskRequestData_DataOneof_TaxInformationCompletedTaskRequestData,
  Core_OrderV3TaskResolutionData_DataOneof_LeaseAssignmentPreparedTaskResolutionData,
  Core_OrderV3TaskResolutionData_DataOneof_LeaseAssignmentSignedTaskResolutionData,
  Core_OrderV3TaskResolutionData_DataOneof_PropertyQuestionnaireCompletedTaskResolutionData,
  Core_OrderV3TaskStatus,
  Core_Task,
  InvestorGetOrderV3ContributionAgreementUrlDocument,
  InvestorGetOrderV3Document,
  InvestorGetOrderV3LeaseAssignmentUrlDocument,
  InvestorGetOrderV3TitleAuthorizationUrlDocument,
  InvestorGetTaskDocument,
  InvestorUpdateOrderV3TaskDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { ProgressIndicatorStatus, useCallbackDebugger } from '@flock/shared-ui'
import theme from '@flock/shared-ui/src/theme/theme'
import { useMediaQuery } from '@mui/material'
import { useParams } from '@reach/router'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { OrderProgressState } from './OrderV3HomeSection'
import { OrderTaskItem, OrderV3PageProps } from './orderV3Types'
import { ContributionAgreementOneTimeLinkProps } from './SignContributionAgreementModal'
import {
  LP_ONBOARDING_URL,
  PERSONAL_INFORMATION_URL,
  PROPERTY_QUESTIONNAIRE_URL,
  ENTITY_INFORMATION_URL,
  TAX_INFORMATION_URL,
  FINANCIAL_INFORMATION_URL,
  ENABLE_SIMULATOR,
} from '../../../constants'

const getTaskByKey = (
  taskArray: Core_OrderV3Task[],
  taskKey: Core_OrderV3TaskKey
) => taskArray.find((task: Core_OrderV3Task) => task.key === taskKey)

const isTaskComplete = (task: Core_OrderV3Task) => {
  if (
    task.key ===
      Core_OrderV3TaskKey.OrderV3TaskKeyContributionAgreementSignedAuthorizedSigner ||
    task.key ===
      Core_OrderV3TaskKey.OrderV3TaskKeySubscriptionDocumentsSignedLegalEntity
  ) {
    return (
      task.status === Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted ||
      task.status === Core_OrderV3TaskStatus.OrderV3TaskStatusStarted
    )
  } else if (
    task.key ===
    Core_OrderV3TaskKey.OrderV3TaskKeyTitleAuthorizationSignedPropertyContribution
  ) {
    return (
      task.status === Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted ||
      task.status === Core_OrderV3TaskStatus.OrderV3TaskStatusStarted ||
      task.status === Core_OrderV3TaskStatus.OrderV3TaskStatusFailed
    )
  }
  return task.status === Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted
}

const isTaskFailed = (task: Core_OrderV3Task) =>
  task.status === Core_OrderV3TaskStatus.OrderV3TaskStatusFailed

const dateFormat = 'MMM D, YYYY'

const addWorkdays = (startDate: string, workDays: number) => {
  let currentDate = moment(startDate)
  let daysRemaining = workDays
  while (daysRemaining > 0) {
    currentDate = currentDate.add(1, 'days')
    if (currentDate.day() !== 0 && currentDate.day() !== 6) {
      daysRemaining -= 1
    }
  }
  return currentDate
}

const subtractWorkdays = (startDate: string, workDays: number) => {
  let currentDate = moment(startDate)
  let daysRemaining = workDays
  while (daysRemaining > 0) {
    currentDate = currentDate.subtract(1, 'days')
    if (currentDate.day() !== 0 && currentDate.day() !== 6) {
      daysRemaining -= 1
    }
  }
  return currentDate
}

const useOrderV3Page = (orderV3Props: OrderV3PageProps) => {
  const { legalEntities: investorLegalEntities } = orderV3Props
  const [loading, setLoading] = useState(true)
  const [orderParseError, setOrderParseError] = useState(false)
  const [docusignFetchError, setDocusignFetchError] = useState(false)
  const [reRenderSwitch, setReRenderSwitch] = useState(false)
  const [signedLeaseAssignments, setSignedLeaseAssignments] = useState<
    string[]
  >([])
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [
    isSignContributionAgreementModalOpen,
    setIsSignContributionAgreementModalOpen,
  ] = useState(false)

  const [progressState, setProgressState] = useState<OrderProgressState>({
    isStep1Complete: false,
    targetCloseDate: '',
    titleCompany: '',
    operatorUuid: '',
    isWaitingOnOtherInvestorsToCompleteSteps: false,
    progressBarConfigs: [],

    currentTasks: [],
    uncompletedTasks: [],
    completedTasks: [],
  })

  const [openPrimaryCADocusignUrl, setOpenPrimaryCADocusignUrl] = useState<
    () => void
  >(() => () => {})
  const [primaryCASigned, setPrimaryCASigned] = useState(false)

  const [openPersonalInfoUrl, setOpenPersonalInfoUrl] = useState<() => void>(
    null as any
  )

  const { orderUuid } = useParams()
  let params: URLSearchParams | null = null
  if (typeof window !== `undefined`) {
    params = new URLSearchParams(window.location.search)
  }
  const taskUuid = params?.get('taskUuid')
  const signedAddressUuid = params?.get('addressUuid')
  const eventStatus = params?.get('event')

  const [updateTask] = useMutation(InvestorUpdateOrderV3TaskDocument)

  const { refetch: fetchOrder } = useQuery(InvestorGetOrderV3Document, {
    variables: {
      input: {
        uuid: orderUuid,
      },
    },
    skip: true,
  })

  const { refetch: fetchTask } = useQuery(InvestorGetTaskDocument, {
    variables: {
      input: {
        taskUuid: taskUuid!,
      },
    },
    skip: true,
  })

  const { refetch: fetchContributionSigningUrl } = useQuery(
    InvestorGetOrderV3ContributionAgreementUrlDocument,
    {
      skip: true,
    }
  )

  const { refetch: fetchTitleAuthorizationUrl } = useQuery(
    InvestorGetOrderV3TitleAuthorizationUrlDocument,
    {
      skip: true,
    }
  )

  const { refetch: fetchLeaseAssignmentUrl } = useQuery(
    InvestorGetOrderV3LeaseAssignmentUrlDocument,
    {
      skip: true,
    }
  )

  const [
    contributionAgreementOneTimeLinkProps,
    setContributionAgreementOneTimeLinkProps,
  ] = useState<ContributionAgreementOneTimeLinkProps>({
    orderUuid,
    taskUuid: '',
    legalEntityUuid: '',
    email: '',
    generateAndOpenCADocusignLink: async () => {},
    isOneTimeLinkSent: false,
  })

  const generateAndOpenCADocusignLink = async (caSigningtaskUuid: string) => {
    try {
      const urlData = await fetchContributionSigningUrl({
        input: {
          orderUuid,
          taskUuid: caSigningtaskUuid,
        },
      })
      const url = urlData.data?.getOrderV3ContributionAgreementUrl?.url
      window.open(url, '_self')
    } catch (e) {
      setDocusignFetchError(true)
    }
  }

  const generateAndOpenTADocusignLink = async (taSigningtaskUuid: string) => {
    try {
      const urlData = await fetchTitleAuthorizationUrl({
        input: {
          orderUuid,
          taskUuid: taSigningtaskUuid,
        },
      })
      const url = urlData.data?.getOrderV3TitleAuthorizationUrl?.url
      window.open(url, '_self')
    } catch (e) {
      setDocusignFetchError(true)
    }
  }

  const generateAndOpenLADocusignLink = async (
    laSigningtaskUuid: string,
    addressUuid: string
  ) => {
    try {
      const urlData = await fetchLeaseAssignmentUrl({
        input: {
          orderUuid,
          taskUuid: laSigningtaskUuid,
          addressUuid,
        },
      })
      const url = urlData.data?.getOrderV3LeaseAssignmentUrl?.url
      window.open(url, '_self')
    } catch (e) {
      setDocusignFetchError(true)
    }
  }

  const getOrderV3PageInfoHelper = useCallbackDebugger(async () => {
    // const getOrderV3PageInfoHelper = useCallbackDebugger(async () => {
    setLoading(true)

    // Update offer immediately after signing CA
    const orderData = await fetchOrder()

    const order = orderData.data?.getOrderV3?.order as Core_OrderV3

    const {
      createdAt,
      // finalOfferPriceCents,
      targetCloseDate,
      orderLegalEntities,
      orderPropertyContributions,
      operatorUuid,
    } = order

    if (ENABLE_SIMULATOR && window.location?.href.includes('simulator')) {
      console.log('order', order)
    }

    const orderCompletedTasks: OrderTaskItem[] = []
    const orderUncompletedTasks: OrderTaskItem[] = []
    const orderCurrentTasks: OrderTaskItem[] = []
    const orderFailedTasks: OrderTaskItem[] = []

    const progressBarConfigs = []

    let isStep1Complete: boolean = false
    let isWaitingOnOtherInvestorsToCompleteSteps: boolean = false
    let isStep2Complete: boolean = false
    let isStep3Complete: boolean = false
    let isStep4Complete: boolean = false
    let isStep5Complete: boolean = false

    let contributionAgreementOverText: string = ''
    let personalInformationOverText: string = ''
    let entityInformationOverText: string = ''
    let propertyQuestionnaireOverText: string = ''
    let taxQuestionnaireOverText: string = ''
    let titleAuthorizationOverText: string = ''
    let leaseAssignmentOverText: string = ''
    let lpOnboardingOverText: string = ''

    const authorizedSignersCompletedCA: Set<string> = new Set()
    let latestContributionAgreementSignedDate: Date | null = null

    // CHECK CONTRIBUTION AGREEMENTS
    orderLegalEntities.forEach(async (legalEntity) => {
      const { authorizedSigners } = legalEntity

      if (authorizedSigners.length === 0) {
        latestContributionAgreementSignedDate = createdAt
        setPrimaryCASigned(true)
      }

      // for each signer, check their contribution agreement process
      authorizedSigners.forEach(async (signer) => {
        let isContributionAgreementComplete = false
        let isOneTimeLinkSentToSecondarySigner = false

        const contributionAgreementSignedTask = getTaskByKey(
          signer.contributionAgreementProcess,
          Core_OrderV3TaskKey.OrderV3TaskKeyContributionAgreementSignedAuthorizedSigner
        )
        const contributionAgreementPreparedTask = getTaskByKey(
          signer.contributionAgreementProcess,
          Core_OrderV3TaskKey.OrderV3TaskKeyContributionAgreementPreparedAuthorizedSigner
        )

        // If these two tasks don't exist, it means that we have a manual CA upload.
        if (
          contributionAgreementSignedTask &&
          contributionAgreementPreparedTask
        ) {
          isContributionAgreementComplete = isTaskComplete(
            contributionAgreementSignedTask
          )
          if (isContributionAgreementComplete) {
            if (
              latestContributionAgreementSignedDate === null ||
              contributionAgreementSignedTask.startedAt >
                latestContributionAgreementSignedDate ||
              contributionAgreementSignedTask.completedAt >
                latestContributionAgreementSignedDate
            ) {
              latestContributionAgreementSignedDate =
                contributionAgreementSignedTask.startedAt ||
                contributionAgreementSignedTask.completedAt
            }
            authorizedSignersCompletedCA.add(signer.name)
          } else {
            // Check if the task is not complete, it may be because it's a secondary signer. Check if one time link has been sent already
            const requestData = contributionAgreementSignedTask.requestData
              ?.data as Core_OrderV3TaskRequestData_DataOneof_ContributionAgreementSignedTaskRequestData
            const oneTimeLinkData = requestData
              ?.contributionAgreementSignedTaskRequestData
              ?.data as Core_OrderV3AuthorizedSignerRequestData
            if (oneTimeLinkData?.oneTimeLinksSent.length > 0) {
              isOneTimeLinkSentToSecondarySigner = true
            }
          }

          // only show investor the tasks that are under their legal entities
          const showTaskToInvestor = investorLegalEntities.some(
            (investorLegalEntity) =>
              legalEntity.legalEntityUuid === investorLegalEntity.uuid
          )
          if (showTaskToInvestor) {
            if (isContributionAgreementComplete) {
              orderCompletedTasks.push({
                title: `Contribution Agreement (${signer.name})`,
              })
              // signal primary CA signed to prevent welcome modal
              if (signer.isPrimary) {
                setPrimaryCASigned(true)
              }
            } else {
              let signCAFunction = async () => {
                await generateAndOpenCADocusignLink(
                  contributionAgreementPreparedTask.taskUuid
                )
              }
              let subtitle = `${signer.name} (${
                signer.isPrimary ? 'Primary' : 'Secondary'
              })`
              let isPending = false

              if (!signer.isPrimary) {
                // check to see if the one time link has been sent to the signer
                if (isOneTimeLinkSentToSecondarySigner) {
                  subtitle = `${signer.name} (Email sent to ${signer.email}, awaiting execution)`
                  isPending = true
                  signCAFunction = async () => {}
                } else {
                  signCAFunction = async () => {
                    setContributionAgreementOneTimeLinkProps({
                      orderUuid,
                      taskUuid: contributionAgreementSignedTask.taskUuid,
                      legalEntityUuid: legalEntity.legalEntityUuid,
                      email: signer.email,
                      generateAndOpenCADocusignLink: async () => {
                        await generateAndOpenCADocusignLink(
                          contributionAgreementPreparedTask.taskUuid
                        )
                      },
                      isOneTimeLinkSent: false,
                    })
                    setIsSignContributionAgreementModalOpen(true)
                  }
                }
              } else {
                setOpenPrimaryCADocusignUrl(() => signCAFunction)
              }
              orderCurrentTasks.push({
                title: `Contribution Agreement`,
                subtitle,
                isPending,
                onClick: signCAFunction,
              })
            }
            // if we don't want to show the task, and this task isn't complete, it means we're waiting on other investors to complete CA
          } else if (!isContributionAgreementComplete) {
            isWaitingOnOtherInvestorsToCompleteSteps = true
          }
        } else {
          latestContributionAgreementSignedDate = createdAt
          orderCompletedTasks.push({
            title: `Contribution Agreement (${signer.name})`,
          })
          authorizedSignersCompletedCA.add(signer.name)
          // signal primary CA signed to prevent welcome modal
          if (signer.isPrimary) {
            setPrimaryCASigned(true)
          }
        }
      })
    })

    let contributionAgreementsStatus: ProgressIndicatorStatus = 'current'

    const currentDate = moment()
    const contributionAgreementDueDate = moment(
      latestContributionAgreementSignedDate
    )
    const personalInformationDueDate = addWorkdays(
      latestContributionAgreementSignedDate || '',
      1
    )
    const entityInformationDueDate = addWorkdays(
      latestContributionAgreementSignedDate || '',
      1
    )
    const propertyQuestionnaireDueDate = addWorkdays(
      latestContributionAgreementSignedDate || '',
      2
    )
    const taxQuestionnaireDueDate = addWorkdays(
      latestContributionAgreementSignedDate || '',
      2
    )
    const titleAuthorizationDueDate = addWorkdays(
      latestContributionAgreementSignedDate || '',
      2
    )
    const lpOnboardingDueDate = subtractWorkdays(targetCloseDate || '', 7)
    const leaseAssignmentDueDate = subtractWorkdays(targetCloseDate || '', 7)
    const targetCloseDateFormatted = moment(targetCloseDate).format(dateFormat)

    // if all CAs are signed, update due dates on progress bar
    if (
      orderCurrentTasks.length === 0 &&
      !isWaitingOnOtherInvestorsToCompleteSteps
    ) {
      contributionAgreementsStatus = 'complete'

      contributionAgreementOverText =
        contributionAgreementDueDate.format(dateFormat)
      personalInformationOverText =
        personalInformationDueDate.format(dateFormat)
      entityInformationOverText = entityInformationDueDate.format(dateFormat)
      propertyQuestionnaireOverText =
        propertyQuestionnaireDueDate.format(dateFormat)
      taxQuestionnaireOverText = taxQuestionnaireDueDate.format(dateFormat)
      titleAuthorizationOverText = titleAuthorizationDueDate.format(dateFormat)
      leaseAssignmentOverText = leaseAssignmentDueDate.format(dateFormat)
      lpOnboardingOverText = lpOnboardingDueDate.format(dateFormat)
    }

    progressBarConfigs.push({
      text: 'Contribution Agreement',
      status: contributionAgreementsStatus,
      overText: contributionAgreementOverText,
    })

    // CHECK PERSONAL INFORMATION TASKS FOR AUTHORIZED SIGNERS
    let personalInformationStatus: ProgressIndicatorStatus = 'next'
    let hasPersonalInformationTask: boolean = false

    orderLegalEntities.forEach((legalEntity) => {
      const { authorizedSigners } = legalEntity
      authorizedSigners.forEach((signer) => {
        let isPersonalInformationComplete = false
        let isPersonalInformationFailed = false
        let isOneTimeLinkSentToSecondarySigner = false

        // we currently only have 1 task for personal information process
        const personalInformationTask = getTaskByKey(
          signer.personalInformationProcess,
          Core_OrderV3TaskKey.OrderV3TaskKeyPersonalInformationCompletedAuthorizedSigner
        )

        if (personalInformationTask) {
          hasPersonalInformationTask = true
          isPersonalInformationComplete = isTaskComplete(
            personalInformationTask
          )
          isPersonalInformationFailed = isTaskFailed(personalInformationTask)
          if (!isPersonalInformationComplete && !isPersonalInformationFailed) {
            const requestData = personalInformationTask?.requestData
              ?.data as Core_OrderV3TaskRequestData_DataOneof_PersonalInformationCompletedTaskRequestData
            const oneTimeLinkData = requestData
              ?.personalInformationCompletedTaskRequestData
              ?.data as Core_OrderV3AuthorizedSignerRequestData
            if (oneTimeLinkData?.oneTimeLinksSent.length > 0) {
              isOneTimeLinkSentToSecondarySigner = true
            }
          }

          // only show investor the tasks that are under their legal entities
          const showTaskToInvestor = investorLegalEntities.some(
            (investorLegalEntity) =>
              legalEntity.legalEntityUuid === investorLegalEntity.uuid
          )
          if (showTaskToInvestor) {
            if (isPersonalInformationComplete || isPersonalInformationFailed) {
              orderCompletedTasks.push({
                title: `Personal Information (${signer.name})`,
              })
              if (isPersonalInformationFailed) {
                orderFailedTasks.push({
                  title: `Personal Information (${signer.name})`,
                })
              }
              // if uncomplete, we need to see if they have signed the CA
              // this is to know if this is a current task or an uncompleted task
            } else if (authorizedSignersCompletedCA.has(signer.name)) {
              if (isOneTimeLinkSentToSecondarySigner && !signer.isPrimary) {
                orderCurrentTasks.push({
                  title: `Personal Information`,
                  subtitle: `${signer.name} (Contribution agreement completed, personal information awaiting execution)`,
                  isPending: true,
                  onClick: async () => {},
                })
              } else {
                if (
                  orderLegalEntities.length === 1 &&
                  authorizedSigners.length === 1
                ) {
                  setOpenPersonalInfoUrl(() => () => {
                    navigate(
                      `${PERSONAL_INFORMATION_URL}/${orderUuid}/${legalEntity.legalEntityUuid}/${personalInformationTask.taskUuid}`
                    )
                  })
                }
                orderCurrentTasks.push({
                  title: `Personal Information (${signer.name})`,
                  onClick: () =>
                    navigate(
                      `${PERSONAL_INFORMATION_URL}/${orderUuid}/${legalEntity.legalEntityUuid}/${personalInformationTask.taskUuid}`
                    ),
                })
              }
              personalInformationStatus = 'current'
            } else {
              orderUncompletedTasks.push({
                title: `Personal Information (${signer.name})`,
              })
            }
            // if we don't want to show the task, and this task isn't complete, it means we're waiting on other investors to complete Personal Information
          } else if (
            !isPersonalInformationComplete &&
            !isPersonalInformationFailed
          ) {
            isWaitingOnOtherInvestorsToCompleteSteps = true
          }
        }
      })
    })

    // at this stage, check uncompleted tasks. if there no uncompleted tasks, it means we've finished contribution agreement and personal information steps
    if (orderUncompletedTasks.length === 0 && orderCurrentTasks.length === 0) {
      personalInformationStatus = 'complete'

      // if uncompleted check if it is past due, and show only if we are already showing a date
    } else if (
      personalInformationDueDate < currentDate &&
      personalInformationOverText !== ''
    ) {
      personalInformationOverText = 'Past Due'
      personalInformationStatus = 'overdue'
    }
    if (hasPersonalInformationTask) {
      progressBarConfigs.push({
        text: 'Personal Information',
        status: personalInformationStatus,
        overText: personalInformationOverText,
      })
    }

    // CHECK ENTITY INFORMATION TASKS FOR LEGAL ENTITIES
    let entityInformationStatus: ProgressIndicatorStatus = 'next'
    let hasSellingEntity: boolean = false
    orderLegalEntities.forEach((legalEntity) => {
      const { authorizedSigners, sellingEntities } = legalEntity

      // only show investor the tasks that are under their legal entities
      const showTaskToInvestor = investorLegalEntities.some(
        (investorLegalEntity) =>
          legalEntity.legalEntityUuid === investorLegalEntity.uuid
      )

      if (sellingEntities.length === 0) {
        return
      } else if (showTaskToInvestor) {
        hasSellingEntity = true
      }

      // firstly we want to check if selling entity information is a current task.
      // we need to see if the PRIMARY authorized signer of the legal entity has signed the CA
      let completedContributionAgreement = false
      if (authorizedSigners.length === 0) {
        completedContributionAgreement = true
      }
      authorizedSigners.forEach((signer) => {
        if (signer.isPrimary && authorizedSignersCompletedCA.has(signer.name)) {
          completedContributionAgreement = true
        }
      })

      // if it's incomplete, render unseparated incomplete task
      if (!completedContributionAgreement && hasSellingEntity) {
        orderUncompletedTasks.push({
          title: `Entity Information (${legalEntity?.legalEntity?.name})`,
        })
        // otherwise check if each task goes into pending or completed
      } else {
        sellingEntities.forEach((sellingEntity) => {
          const sellingEntityInfoTask = getTaskByKey(
            sellingEntity.sellingEntityProcess,
            Core_OrderV3TaskKey.OrderV3TaskKeySellingEntityInformationCompletedLegalEntity
          )
          if (sellingEntityInfoTask) {
            const sellingEntityInfoProcessComplete = isTaskComplete(
              sellingEntityInfoTask
            )
            const sellingEntityInfoProcessFailed = isTaskFailed(
              sellingEntityInfoTask
            )
            if (
              sellingEntityInfoProcessComplete ||
              sellingEntityInfoProcessFailed
            ) {
              orderCompletedTasks.push({
                title: `Entity Information (${sellingEntity.name})`,
              })
              if (sellingEntityInfoProcessFailed) {
                orderFailedTasks.push({
                  title: `Entity Information (${sellingEntity.name})`,
                })
              }
              // if uncomplete and for the investor, push to current tasks to do. if it's for a different investor, set the waiting status
            } else if (showTaskToInvestor) {
              orderCurrentTasks.push({
                title: `Entity Information (${sellingEntity.name})`,
                onClick: () =>
                  navigate(
                    `${ENTITY_INFORMATION_URL}/${orderUuid}/${legalEntity.legalEntityUuid}/${sellingEntityInfoTask.taskUuid}`
                  ),
              })
              entityInformationStatus = 'current'
            } else {
              isWaitingOnOtherInvestorsToCompleteSteps = true
            }
          } else {
            setOrderParseError(true)
          }
        })
      }
    })

    // at this stage, check uncompleted tasks. if there no uncompleted tasks, it means we've finished contribution agreement and personal information steps
    if (
      orderUncompletedTasks.length === 0 &&
      orderCurrentTasks.length === 0 &&
      !isWaitingOnOtherInvestorsToCompleteSteps
    ) {
      entityInformationStatus = 'complete'
      if (orderFailedTasks.length === 0) {
        isStep1Complete = true
      }
    } else if (
      entityInformationDueDate < currentDate &&
      entityInformationOverText !== ''
    ) {
      entityInformationOverText = 'Past Due'
      entityInformationStatus = 'overdue'
    }
    if (hasSellingEntity) {
      progressBarConfigs.push({
        text: 'Entity Information',
        status: entityInformationStatus,
        overText: entityInformationOverText,
      })
    }

    // PARSE PROPERTY CONTRIBUTION TASKS
    // check to see if CA tasks and information tasks are complete. if so, separate out property contribution tasks into current tasks
    let isPropertyQuestionnaireSkipped: Boolean = true
    let leaseAssignmentTasksPresent: Boolean = true
    let propertyQuestionnaireStatus: ProgressIndicatorStatus = 'next'
    let orderTitleCompany = ''
    const completedMultiunitQuestionnaires: Set<string> = new Set()

    // this loop does two things:
    // 1. populates completedMultiunitQuestionnaires with uuids of multiunit addresses that have completed the property questionnaire
    // 2. checks if the property questionnaire is skipped or not
    orderPropertyContributions.forEach((contribution) => {
      const {
        address,
        propertyInformationProcess,
        titleCompany,
        propertyOnboardingProcess,
      } = contribution
      orderTitleCompany = titleCompany
      const propertyContributionTask = getTaskByKey(
        propertyInformationProcess,
        Core_OrderV3TaskKey.OrderV3TaskKeyPropertyQuestionnaireCompletedPropertyContribution
      )
      if (propertyContributionTask) {
        isPropertyQuestionnaireSkipped = false

        if (address?.units && address?.units?.length > 0) {
          // if multiunit, addresses in this set have completed the property questionnaire
          const resolutionData = propertyContributionTask?.resolutionData
            ?.data as Core_OrderV3TaskResolutionData_DataOneof_PropertyQuestionnaireCompletedTaskResolutionData
          resolutionData?.propertyQuestionnaireCompletedTaskResolutionData?.perUnitData?.forEach(
            (unit) => {
              completedMultiunitQuestionnaires.add(unit.addressUuid)
            }
          )
        }
      }
      const leaseAssignmentTask = getTaskByKey(
        propertyOnboardingProcess,
        Core_OrderV3TaskKey.OrderV3TaskKeyLeaseAssignmentPreparedPropertyContribution
      )
      if (!leaseAssignmentTask) {
        leaseAssignmentTasksPresent = false
      }
    })

    if (isStep1Complete === true) {
      // begin parsing property questionnaire tasks if property questionnaire is not skipped
      if (!isPropertyQuestionnaireSkipped) {
        orderPropertyContributions.forEach((contribution) => {
          const { address, propertyInformationProcess } = contribution
          // if this is a multiunit address, we need to check if the units have completed the property questionnaire
          if (address?.units && address?.units?.length > 0) {
            propertyInformationProcess.forEach((task) => {
              if (
                task.key ===
                Core_OrderV3TaskKey.OrderV3TaskKeyPropertyQuestionnaireCompletedPropertyContribution
              ) {
                const isPropertyContributionTaskFailed = isTaskFailed(task)
                if (isPropertyContributionTaskFailed) {
                  orderCompletedTasks.push({
                    title: `Property Questionnaire (${address.formattedAddress})`,
                  })
                  orderFailedTasks.push({
                    title: `Property Questionnaire (${address.formattedAddress})`,
                  })
                } else {
                  address?.units?.forEach((unit) => {
                    // if it's there, add to completed tasks
                    if (completedMultiunitQuestionnaires.has(unit!.uuid)) {
                      orderCompletedTasks.push({
                        title: `Property Questionnaire (${
                          unit!.formattedAddress
                        })`,
                      })

                      // if they are complete with CA and Information, move to current tasks
                    } else {
                      orderCurrentTasks.push({
                        title: `Property Questionnaire (${
                          unit!.formattedAddress
                        })`,
                        onClick: () =>
                          navigate(
                            `${PROPERTY_QUESTIONNAIRE_URL}/${orderUuid}/${
                              contribution.propertyContributionUuid
                            }/${unit!.uuid}/${task.taskUuid}`
                          ),
                      })
                      propertyQuestionnaireStatus = 'current'
                    }
                    // otherwise move to pending
                  })
                }
              }
            })
            // in this case, it's just a single unit address
          } else {
            const propertyContributionTask = getTaskByKey(
              propertyInformationProcess,
              Core_OrderV3TaskKey.OrderV3TaskKeyPropertyQuestionnaireCompletedPropertyContribution
            )
            if (propertyContributionTask) {
              const isPropertyContributionTaskComplete = isTaskComplete(
                propertyContributionTask
              )
              const isPropertyContributionTaskFailed = isTaskFailed(
                propertyContributionTask
              )
              if (
                isPropertyContributionTaskComplete ||
                isPropertyContributionTaskFailed
              ) {
                orderCompletedTasks.push({
                  title: `Property Questionnaire (${address.formattedAddress})`,
                })
                if (isPropertyContributionTaskFailed) {
                  orderFailedTasks.push({
                    title: `Property Questionnaire (${address.formattedAddress})`,
                  })
                }
              } else {
                // if they are complete with CA and Information, move to current tasks
                orderCurrentTasks.push({
                  title: `Property Questionnaire (${address.formattedAddress})`,
                  onClick: () =>
                    navigate(
                      `${PROPERTY_QUESTIONNAIRE_URL}/${orderUuid}/${contribution.propertyContributionUuid}/${address.uuid}/${propertyContributionTask.taskUuid}`
                    ),
                })
                propertyQuestionnaireStatus = 'current'
              }
            }
          }
        })
      }
    } else if (!isPropertyQuestionnaireSkipped) {
      orderUncompletedTasks.push({
        title: `Property Questionnaire Tasks`,
      })
    }

    // at this stage, check uncompleted tasks again. if there no uncompleted tasks, it means we've finished property contribution steps
    if (orderUncompletedTasks.length === 0 && orderCurrentTasks.length === 0) {
      propertyQuestionnaireStatus = 'complete'
      if (orderFailedTasks.length === 0) {
        isStep2Complete = true
      }
    } else if (
      propertyQuestionnaireDueDate < currentDate &&
      propertyQuestionnaireOverText !== ''
    ) {
      propertyQuestionnaireOverText = 'Past Due'
      propertyQuestionnaireStatus = 'overdue'
    }
    if (!isPropertyQuestionnaireSkipped) {
      progressBarConfigs.push({
        text: 'Property Questionnaire',
        status: propertyQuestionnaireStatus,
        overText: propertyQuestionnaireOverText,
      })
    }

    // PARSE TAX QUESTIONNAIRE TASK
    // if they are complete with CA, Information, and Property Contribution, parse these into current tasks
    let taxQuestionnaireStatus: ProgressIndicatorStatus = 'next'
    let isTaxQuestionnaireSkipped: Boolean = true

    orderPropertyContributions.forEach((contribution) => {
      const { address, propertyInformationProcess } = contribution

      const taxInformationTask = getTaskByKey(
        propertyInformationProcess,
        Core_OrderV3TaskKey.OrderV3TaskKeyTaxInformationCompletedPropertyContribution
      )

      if (taxInformationTask) {
        const taxInfoResolutionData = taxInformationTask.requestData
          ?.data as Core_OrderV3TaskRequestData_DataOneof_TaxInformationCompletedTaskRequestData

        isTaxQuestionnaireSkipped =
          taxInfoResolutionData.taxInformationCompletedTaskRequestData
            ?.suppressed || false
        if (!isTaxQuestionnaireSkipped) {
          if (isStep2Complete) {
            const isTaxInformationProcessComplete =
              isTaskComplete(taxInformationTask)
            const isTaxInformationProcessFailed =
              isTaskFailed(taxInformationTask)
            if (
              isTaxInformationProcessComplete ||
              isTaxInformationProcessFailed
            ) {
              orderCompletedTasks.push({
                title: `Tax Questionnaire (${address.formattedAddress})`,
              })
              if (isTaxInformationProcessFailed) {
                orderFailedTasks.push({
                  title: `Tax Questionnaire (${address.formattedAddress})`,
                })
              }
            } else {
              orderCurrentTasks.push({
                title: `Tax Questionnaire (${address.formattedAddress})`,
                onClick: () =>
                  navigate(
                    `${TAX_INFORMATION_URL}/${orderUuid}/${contribution.propertyContributionUuid}/${taxInformationTask.taskUuid}`
                  ),
              })
              taxQuestionnaireStatus = 'current'
            }
          }
        }
      } else {
        setOrderParseError(true)
      }
    })
    // otherwise just push generic label to uncompleted tasks
    if (!isTaxQuestionnaireSkipped && !isStep2Complete) {
      orderUncompletedTasks.push({
        title: `Tax Questionnaire`,
      })
    }

    // at this stage, check uncompleted tasks again. if there no uncompleted tasks, it means we've finished property contribution steps
    if (orderUncompletedTasks.length === 0 && orderCurrentTasks.length === 0) {
      taxQuestionnaireStatus = 'complete'
      if (orderFailedTasks.length === 0) {
        isStep3Complete = true
      }
    } else if (
      taxQuestionnaireDueDate < currentDate &&
      taxQuestionnaireOverText !== ''
    ) {
      taxQuestionnaireOverText = 'Past Due'
      taxQuestionnaireStatus = 'overdue'
    }
    if (!isTaxQuestionnaireSkipped) {
      progressBarConfigs.push({
        text: 'Tax Questionnaire',
        status: taxQuestionnaireStatus,
        overText: taxQuestionnaireOverText,
      })
    }

    // OWNER AFFIDAVIT AND TITLE AUTHORIZATION
    // if they are complete with CA, Information, Property Contribution, and Tax Questionnaire, parse owner affidavit and title authorization tasks tasks
    // if property questionnaire is skipped, we don't render title tasks
    let titleAuthorizationStatus: ProgressIndicatorStatus = 'next'
    let currentTaskUuid = ''
    if (isStep3Complete) {
      // begin parsing title auth tasks if property questionnaire is not skipped
      if (!isPropertyQuestionnaireSkipped) {
        // check if all owner affidavits are filled out
        let isOwnerAffadavitFormComplete = true
        let isOwnerAffadavitFormFailed = false
        orderPropertyContributions.forEach((contribution) => {
          const { propertyInformationProcess } = contribution

          const ownerAffadavitFormTask = getTaskByKey(
            propertyInformationProcess,
            Core_OrderV3TaskKey.OrderV3TaskKeyFinancialInformationCompletedPropertyContribution
          )

          if (ownerAffadavitFormTask) {
            currentTaskUuid = ownerAffadavitFormTask.taskUuid
            const isOwnerAffadavitFormForPropertyComplete = isTaskComplete(
              ownerAffadavitFormTask
            )
            const isOwnerAffadavitFormForPropertyFailed = isTaskFailed(
              ownerAffadavitFormTask
            )
            if (
              !isOwnerAffadavitFormForPropertyComplete &&
              !isOwnerAffadavitFormForPropertyFailed
            ) {
              isOwnerAffadavitFormComplete = false
            }
            if (isOwnerAffadavitFormForPropertyFailed) {
              isOwnerAffadavitFormFailed = true
            }
          }
        })
        // if owner affidavits are not all complete, take them to the owner's affidavit form
        if (!isOwnerAffadavitFormComplete) {
          orderCurrentTasks.push({
            title: `Owner Affidavit & Title Authorization`,
            onClick: () =>
              navigate(
                `${FINANCIAL_INFORMATION_URL}/${orderUuid}/${currentTaskUuid}`
              ),
          })
          titleAuthorizationStatus = 'current'
          // if it is complete, parse title auth signing
        } else {
          orderCompletedTasks.push({
            title: `Owner Affidavit`,
          })
          if (isOwnerAffadavitFormFailed) {
            orderFailedTasks.push({
              title: `Owner Affidavit`,
            })
          }
          if (
            !isPropertyQuestionnaireSkipped &&
            orderFailedTasks.length === 0
          ) {
            orderPropertyContributions.forEach((contribution) => {
              const { titleAndClosingProcess } = contribution

              const titleAuthorizationSignedTask = getTaskByKey(
                titleAndClosingProcess,
                Core_OrderV3TaskKey.OrderV3TaskKeyTitleAuthorizationSignedPropertyContribution
              )
              const titleAuthorizationPreparedTask = getTaskByKey(
                titleAndClosingProcess,
                Core_OrderV3TaskKey.OrderV3TaskKeyTitleAuthorizationPreparedPropertyContribution
              )
              if (
                titleAuthorizationSignedTask &&
                titleAuthorizationPreparedTask
              ) {
                const isTitleAndClosingSignedTaskComplete = isTaskComplete(
                  titleAuthorizationSignedTask
                )

                if (isTitleAndClosingSignedTaskComplete) {
                  orderCompletedTasks.push({
                    title: `Sign Title Authorization for (${contribution.address.formattedAddress})`,
                  })
                } else {
                  orderCurrentTasks.push({
                    title: `Sign Title Authorization for (${contribution.address.formattedAddress})`,
                    onClick: async () => {
                      await generateAndOpenTADocusignLink(
                        titleAuthorizationPreparedTask.taskUuid
                      )
                    },
                  })
                  titleAuthorizationStatus = 'current'
                }
              } else {
                setOrderParseError(true)
              }
            })
          }
        }
        // if step3 is not complete, push generic label to uncompleted tasks
      }
    } else if (!isPropertyQuestionnaireSkipped) {
      orderUncompletedTasks.push({
        title: `Owner Affidavit & Title Authorization`,
      })
    }

    // at this stage, check uncompleted tasks again. if there no uncompleted tasks, it means we're ready to move onto LP Onboarding
    if (orderUncompletedTasks.length === 0 && orderCurrentTasks.length === 0) {
      titleAuthorizationStatus = 'complete'
      if (orderFailedTasks.length === 0) {
        isStep4Complete = true
      }
    } else if (
      titleAuthorizationDueDate < currentDate &&
      titleAuthorizationOverText !== ''
    ) {
      titleAuthorizationOverText = 'Past Due'
      titleAuthorizationStatus = 'overdue'
    }
    if (!isPropertyQuestionnaireSkipped) {
      progressBarConfigs.push({
        text: 'Title Authorization & Owner Affidavit',
        status: titleAuthorizationStatus,
        overText: titleAuthorizationOverText,
      })
    }

    // CHECK LP ONBOARDING TASKS FOR LEGALENTITIES
    let lpOnboardingStatus: ProgressIndicatorStatus = 'next'
    if (isStep4Complete) {
      orderLegalEntities.forEach((legalEntity) => {
        const { lpOnboardingProcess } = legalEntity
        let isLpOnboardingComplete = true

        const basicInfo = getTaskByKey(
          lpOnboardingProcess,
          Core_OrderV3TaskKey.OrderV3TaskKeyBasicInvestorInfoSubmittedLegalEntity
        )
        const iqs = getTaskByKey(
          lpOnboardingProcess,
          Core_OrderV3TaskKey.OrderV3TaskKeyInvestorQualificationFormCompletedLegalEntity
        )
        const identityAndAccreditation = getTaskByKey(
          lpOnboardingProcess,
          Core_OrderV3TaskKey.OrderV3TaskKeyIdentityAndAccreditationVerificationRequestedLegalEntity
        )
        const subDocsSigned = getTaskByKey(
          lpOnboardingProcess,
          Core_OrderV3TaskKey.OrderV3TaskKeySubscriptionDocumentsSignedLegalEntity
        )
        const isLpOnboardingFailed =
          (subDocsSigned && isTaskFailed(subDocsSigned)) ||
          (identityAndAccreditation &&
            isTaskFailed(identityAndAccreditation)) ||
          (iqs && isTaskFailed(iqs)) ||
          (basicInfo && isTaskFailed(basicInfo))
        if (
          subDocsSigned &&
          !isTaskComplete(subDocsSigned) &&
          !isLpOnboardingFailed
        ) {
          isLpOnboardingComplete = false
        }

        const showTaskToInvestor = investorLegalEntities.some(
          (investorLegalEntity) =>
            legalEntity.legalEntityUuid === investorLegalEntity.uuid
        )
        if (showTaskToInvestor) {
          if (isLpOnboardingComplete) {
            orderCompletedTasks.push({
              title: `LP Onboarding (${legalEntity?.legalEntity?.name})`,
            })
            if (isLpOnboardingFailed) {
              orderFailedTasks.push({
                title: `LP Onboarding (${legalEntity?.legalEntity?.name})`,
              })
            }
          } else {
            orderCurrentTasks.push({
              title: `LP Onboarding (${legalEntity?.legalEntity?.name})`,
              onClick: () =>
                navigate(`${LP_ONBOARDING_URL}`, {
                  state: {
                    legalEntityUuid: legalEntity.legalEntityUuid,
                    orderUuid,
                  },
                }),
            })
            lpOnboardingStatus = 'current'
          }
        }
      })
    } else {
      orderUncompletedTasks.push({
        title: `LP Onboarding`,
      })
    }

    if (orderUncompletedTasks.length === 0 && orderCurrentTasks.length === 0) {
      lpOnboardingStatus = 'complete'
      if (orderFailedTasks.length === 0) {
        isStep5Complete = true
      }
    } else if (
      lpOnboardingDueDate < currentDate &&
      lpOnboardingOverText !== ''
    ) {
      lpOnboardingOverText = 'Past Due'
      lpOnboardingStatus = 'overdue'
    }
    progressBarConfigs.push({
      text: 'LP Onboarding',
      status: lpOnboardingStatus,
      overText: lpOnboardingOverText,
    })

    // LEASE ASSIGNMENT
    // if they are complete with CA, Information, Property Contribution, Tax Questionnaire, Title, AND Subdocs parse lease assignment tasks
    // if property questionnaire is skipped, we don't render these
    let leaseAssignmentStatus: ProgressIndicatorStatus = 'next'
    if (isStep5Complete) {
      // begin parsing lease assignment tasks if property questionnaire is not skipped
      if (!isPropertyQuestionnaireSkipped && leaseAssignmentTasksPresent) {
        orderPropertyContributions.forEach((contribution) => {
          const { propertyOnboardingProcess, address } = contribution

          const leaseAssignmentSignedTask = getTaskByKey(
            propertyOnboardingProcess,
            Core_OrderV3TaskKey.OrderV3TaskKeyLeaseAssignmentSignedPropertyContribution
          )
          const leaseAssignmentPreparedTask = getTaskByKey(
            propertyOnboardingProcess,
            Core_OrderV3TaskKey.OrderV3TaskKeyLeaseAssignmentPreparedPropertyContribution
          )
          if (leaseAssignmentSignedTask && leaseAssignmentPreparedTask) {
            if (!isTaskComplete(leaseAssignmentSignedTask)) {
              const requestData = leaseAssignmentPreparedTask.requestData
                ?.data as Core_OrderV3TaskRequestData_DataOneof_LeaseAssignmentPreparedTaskRequestData
              const resolutionData = leaseAssignmentPreparedTask.resolutionData
                ?.data as Core_OrderV3TaskResolutionData_DataOneof_LeaseAssignmentPreparedTaskResolutionData
              const signedData = leaseAssignmentSignedTask.resolutionData
                ?.data as Core_OrderV3TaskResolutionData_DataOneof_LeaseAssignmentSignedTaskResolutionData
              const preparedDocuments =
                resolutionData?.leaseAssignmentPreparedTaskResolutionData
                  ?.leaseAssignmentPreparedDocuments
              const signedDocuments =
                signedData?.leaseAssignmentSignedTaskResolutionData
                  ?.leaseAssignmentSignedDocuments
              const leasedAddresses =
                requestData?.leaseAssignmentPreparedTaskRequestData
                  ?.addressUuidsWithLease

              const addressesWithPreparedDocuments: string[] = []
              preparedDocuments?.forEach((preparedDocument) => {
                addressesWithPreparedDocuments.push(
                  preparedDocument.addressUuid
                )
              })
              const signedAddresses: string[] = []
              signedDocuments?.forEach((signedDocument) => {
                signedAddresses.push(signedDocument.addressUuid)
              })
              const leasedAddressesWaitingForDeposit: string[] = []
              leasedAddresses?.forEach((leasedAddress) => {
                if (!addressesWithPreparedDocuments.includes(leasedAddress)) {
                  leasedAddressesWaitingForDeposit.push(leasedAddress)
                }
              })

              addressesWithPreparedDocuments.forEach((unitUuid) => {
                let validatedAddress = address?.formattedAddress || ''
                if (address?.units && address?.units?.length > 0) {
                  address.units.forEach((unit) => {
                    if (unit && unit.uuid === unitUuid) {
                      validatedAddress = unit?.formattedAddress || ''
                    }
                  })
                }
                if (
                  signedAddresses.includes(unitUuid) ||
                  signedLeaseAssignments.includes(unitUuid)
                ) {
                  orderCompletedTasks.push({
                    title: `Sign Lease Assignment for (${validatedAddress})`,
                  })
                } else {
                  orderCurrentTasks.push({
                    title: `Sign Lease Assignment for (${validatedAddress})`,
                    onClick: async () => {
                      await generateAndOpenLADocusignLink(
                        leaseAssignmentPreparedTask.taskUuid,
                        unitUuid
                      )
                    },
                  })
                  leaseAssignmentStatus = 'current'
                }
              })
              leasedAddressesWaitingForDeposit.forEach((unitUuid) => {
                let validatedAddress = address?.formattedAddress || ''
                if (address?.units && address?.units?.length > 0) {
                  address.units.forEach((unit) => {
                    if (unit && unit.uuid === unitUuid) {
                      validatedAddress = unit?.formattedAddress || ''
                    }
                  })
                }
                orderUncompletedTasks.push({
                  title: `Sign Lease Assignment for (${validatedAddress})`,
                })
                leaseAssignmentStatus = 'current'
              })
            } else {
              orderCompletedTasks.push({
                title: `Lease Assignment`,
              })
            }
          } else {
            setOrderParseError(true)
          }
        })
      }
      // if step4 is not complete, push generic label to uncompleted tasks
    } else if (!isPropertyQuestionnaireSkipped && leaseAssignmentTasksPresent) {
      orderUncompletedTasks.push({
        title: `Lease Assignment`,
      })
    }

    // at this stage, check uncompleted tasks again. if there no uncompleted tasks, it means we're ready to move onto LP Onboarding
    if (orderUncompletedTasks.length === 0 && orderCurrentTasks.length === 0) {
      leaseAssignmentStatus = 'complete'
    } else if (
      leaseAssignmentDueDate < currentDate &&
      leaseAssignmentOverText !== ''
    ) {
      leaseAssignmentOverText = 'Past Due'
      leaseAssignmentStatus = 'overdue'
    }
    if (!isPropertyQuestionnaireSkipped && leaseAssignmentTasksPresent) {
      progressBarConfigs.push({
        text: 'Lease Assignment',
        status: leaseAssignmentStatus,
        overText: leaseAssignmentOverText,
      })
    }

    const updatedProgressState = {
      isStep1Complete,
      targetCloseDate: targetCloseDateFormatted,
      titleCompany: orderTitleCompany,
      operatorUuid,
      isWaitingOnOtherInvestorsToCompleteSteps,
      progressBarConfigs,
      currentTasks: orderCurrentTasks,
      uncompletedTasks: orderUncompletedTasks,
      completedTasks: orderCompletedTasks,
    }

    setProgressState(updatedProgressState)
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchOrder,
    fetchContributionSigningUrl,
    orderUuid,
    fetchTitleAuthorizationUrl,
    investorLegalEntities,
  ])

  useEffect(() => {
    const docusignSigningUpdateTaskHelper = async () => {
      try {
        if (taskUuid) {
          const taskData = await fetchTask()
          const task = taskData.data?.task?.task as Core_Task
          if (
            task?.status &&
            task.status !== Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted
          ) {
            if (
              eventStatus === 'signing_complete' ||
              eventStatus === 'viewing_complete'
            ) {
              if (
                task?.type ===
                  Core_OrderV3TaskKey.OrderV3TaskKeyLeaseAssignmentSignedPropertyContribution &&
                signedAddressUuid
              ) {
                setSignedLeaseAssignments((prevAssignments) => [
                  ...prevAssignments,
                  signedAddressUuid,
                ])
                await updateTask({
                  variables: {
                    input: {
                      orderUuid,
                      taskData: {
                        taskUuid: taskUuid || '',
                        completionType:
                          Core_OrderV3TaskCompletionType.OrderV3TaskCompletionTypeCompletedAuthorizedSigner,
                        status: Core_OrderV3TaskStatus.OrderV3TaskStatusStarted,
                        resolutionData: {
                          data: {
                            leaseAssignmentSignedTaskResolutionData: {
                              leaseAssignmentSignedDocuments: [
                                {
                                  addressUuid: signedAddressUuid,
                                  documentUuid: '',
                                },
                              ],
                            },
                          },
                        },
                      },
                    },
                  },
                })
              } else {
                await updateTask({
                  variables: {
                    input: {
                      orderUuid,
                      skipSideEffect: true,
                      taskData: {
                        taskUuid: taskUuid || '',
                        completionType:
                          Core_OrderV3TaskCompletionType.OrderV3TaskCompletionTypeCompletedAuthorizedSigner,
                        status: Core_OrderV3TaskStatus.OrderV3TaskStatusStarted,
                      },
                    },
                  },
                })
              }
            }
          }
        }
      } catch (e) {
        console.error('error updating task', e)
      }

      if (
        investorLegalEntities !== undefined &&
        investorLegalEntities.length !== 0
      ) {
        getOrderV3PageInfoHelper()
      }
    }
    docusignSigningUpdateTaskHelper()
  }, [
    eventStatus,
    getOrderV3PageInfoHelper,
    orderUuid,
    taskUuid,
    updateTask,
    investorLegalEntities,
    reRenderSwitch,
    fetchTask,
    signedAddressUuid,
  ])

  useEffect(() => {}, [isMobile])

  return {
    isMobile,
    loading,
    orderParseError,
    docusignFetchError,
    setDocusignFetchError,

    progressState,
    contributionAgreementOneTimeLinkProps,

    isSignContributionAgreementModalOpen,
    setIsSignContributionAgreementModalOpen,
    openPrimaryCADocusignUrl,
    primaryCASigned,
    openPersonalInfoUrl,
    reRenderSwitch,
    setReRenderSwitch,
  }
}

export default useOrderV3Page
